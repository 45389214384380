:root {
  --cc-bg: #fff;
  --cc-text: #2d4156;
  --cc-border-radius: 0.35rem;
  --cc-btn-primary-bg: #2d4156;
  --cc-btn-primary-text: var(--cc-bg);
  --cc-btn-primary-hover-bg: #1d2e38;
  --cc-btn-secondary-bg: #eaeff2;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #d8e0e6;
  --cc-btn-border-radius: 4px;
  --cc-toggle-bg-off: #919ea6;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #d5dee2;
  --cc-toggle-knob-bg: #fff;
  --cc-toggle-knob-icon-color: #ecf2fa;
  --cc-block-text: var(--cc-text);
  --cc-cookie-category-block-bg: #f0f4f7;
  --cc-cookie-category-block-bg-hover: #e9eff4;
  --cc-section-border: #f1f3f5;
  --cc-cookie-table-border: #e9edf2;
  --cc-overlay-bg: rgba(4, 6, 8, 0.85);
  --cc-webkit-scrollbar-bg: #cfd5db;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}
.c_darkmode {
  --cc-bg: #181b1d;
  --cc-text: #d8e5ea;
  --cc-btn-primary-bg: #a6c4dd;
  --cc-btn-primary-text: #000;
  --cc-btn-primary-hover-bg: #c2dff7;
  --cc-btn-secondary-bg: #33383c;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #3e454a;
  --cc-toggle-bg-off: #667481;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #454c54;
  --cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
  --cc-toggle-knob-icon-color: var(--cc-bg);
  --cc-block-text: #b3bfc5;
  --cc-cookie-category-block-bg: #23272a;
  --cc-cookie-category-block-bg-hover: #2b3035;
  --cc-section-border: #292d31;
  --cc-cookie-table-border: #2b3035;
  --cc-webkit-scrollbar-bg: #667481;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}
.cc_div *,
.cc_div :after,
.cc_div :before,
.cc_div :hover {
  animation: none;
  background: none;
  border: none;
  border-radius: unset;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  float: none;
  font-family: inherit;
  font-size: 1rem;
  font-style: inherit;
  font-variant: normal;
  font-weight: inherit;
  height: auto;
  letter-spacing: unset;
  line-height: 1.2;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-transform: none;
  transition: none;
  vertical-align: baseline;
  visibility: unset;
}
.cc_div {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: #2d4156;
  color: var(--cc-text);
  font-family: -apple-systrem, BlinkMacSystremFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color remoji, Segoe UI remoji, Segoe UI Symbol;
  font-size: 16px;
  font-weight: 400;
}
#c-ttl,
#s-bl td:before,
#s-ttl,
.cc_div .b-tl,
.cc_div .c-bn {
  font-weight: 600;
}
#cm,
#s-bl .act .b-acc,
#s-inr,
.cc_div .b-tl,
.cc_div .c-bl {
  border-radius: 0.35rem;
  border-radius: var(--cc-border-radius);
}
#s-bl .act .b-acc {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.cc_div a,
.cc_div button,
.cc_div input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  overflow: hidden;
}
.cc_div a {
  border-bottom: 1px solid;
}
.cc_div a:hover {
  border-color: transparent;
  text-decoration: none;
}
#cm-ov,
#cs-ov,
.c--anim #cm,
.c--anim #s-cnt,
.c--anim #s-inr {
  transition: visibility 0.25s linear, opacity 0.25s ease, transform 0.25s ease !important;
}
.c--anim .c-bn {
  transition: background-color 0.25s ease !important;
}
.c--anim #cm.bar.slide,
.c--anim #cm.bar.slide + #cm-ov,
.c--anim .bar.slide #s-inr,
.c--anim .bar.slide + #cs-ov {
  transition: visibility 0.4s ease, opacity 0.4s ease, transform 0.4s ease !important;
}
#cm.bar.slide,
.cc_div .bar.slide #s-inr {
  opacity: 1;
  transform: translateX(100%);
}
#cm.bar.top.slide,
.cc_div .bar.left.slide #s-inr {
  opacity: 1;
  transform: translateX(-100%);
}
#cm.slide,
.cc_div .slide #s-inr {
  transform: translateY(1.6rem);
}
#cm.top.slide {
  transform: translateY(-1.6rem);
}
#cm.bar.slide {
  transform: translateY(100%);
}
#cm.bar.top.slide {
  transform: translateY(-100%);
}
.show--consent .c--anim #cm,
.show--consent .c--anim #cm.bar,
.show--settings .c--anim #s-inr,
.show--settings .c--anim .bar.slide #s-inr {
  opacity: 1;
  transform: scale(1);
  visibility: visible !important;
}
.show--consent .c--anim #cm.box.middle,
.show--consent .c--anim #cm.cloud.middle {
  transform: scale(1) translateY(-50%);
}
.show--settings .c--anim #s-cnt {
  visibility: visible !important;
}
.force--consent.show--consent .c--anim #cm-ov,
.show--settings .c--anim #cs-ov {
  opacity: 1 !important;
  visibility: visible !important;
}
#cm {
  background: #fff;
  background: var(--cc-bg);
  bottom: 1.25rem;
  box-shadow: 0 0.625rem 1.875rem #000;
  box-shadow: 0 0.625rem 1.875rem rgba(2, 2, 3, 0.28);
  font-family: inherit;
  line-height: normal;
  max-width: 24.2rem;
  opacity: 0;
  padding: 1.1rem 1.5rem 1.4rem;
  position: fixed;
  right: 1.25rem;
  transform: scale(0.95);
  visibility: hidden;
  width: 100%;
  z-index: 1;
}
#cc_div #cm {
  display: block !important;
}
#c-ttl {
  font-size: 1.05rem;
  margin-bottom: 0.7rem;
}
.cloud #c-ttl {
  margin-top: -0.15rem;
}
#c-txt {
  font-size: 0.9rem;
  line-height: 1.5rem;
}
.cc_div #c-bns {
  display: flex;
  justify-content: space-between;
  margin-top: 1.4rem;
}
.cc_div .c-bn {
  background: #eaeff2;
  background: var(--cc-btn-secondary-bg);
  border-radius: 4px;
  border-radius: var(--cc-btn-border-radius);
  color: #2d4156;
  color: var(--cc-btn-secondary-text);
  cursor: pointer;
  display: inline-block;
  flex: 1;
  font-size: 0.82rem;
  padding: 1rem 1.7rem;
  text-align: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
#c-bns button + button,
#s-c-bn,
#s-cnt button + button {
  float: right;
  margin-left: 1rem;
}
#s-cnt #s-rall-bn {
  float: none;
}
#cm .c_link:active,
#cm .c_link:hover,
#s-c-bn:active,
#s-c-bn:hover,
#s-cnt button + button:active,
#s-cnt button + button:hover {
  background: #d8e0e6;
  background: var(--cc-btn-secondary-hover-bg);
}
#s-cnt {
  display: table;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 101;
}
#s-bl {
  outline: none;
}
#s-bl .title {
  margin-top: 1.4rem;
}
#s-bl .b-bn,
#s-bl .title:first-child {
  margin-top: 0;
}
#s-bl .b-acc .p {
  margin-top: 0;
  padding: 1rem;
}
#s-cnt .b-bn .b-tl {
  background: none;
  display: block;
  font-family: inherit;
  font-size: 0.95rem;
  padding: 1.3rem 6.4rem 1.3rem 2.7rem;
  position: relative;
  transition: background-color 0.25s ease;
  width: 100%;
}
#s-cnt .b-bn .b-tl.exp {
  cursor: pointer;
}
#s-cnt .act .b-bn .b-tl {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
#s-cnt .b-bn .b-tl:active,
#s-cnt .b-bn .b-tl:hover {
  background: #e9eff4;
  background: var(--cc-cookie-category-block-bg-hover);
}
#s-bl .b-bn {
  position: relative;
}
#s-bl .c-bl {
  border: 1px solid #f1f3f5;
  border: 1px solid var(--cc-section-border);
  margin-bottom: 0.5rem;
  padding: 1rem;
  transition: background-color 0.25s ease;
}
#s-bl .c-bl:hover {
  background: #f0f4f7;
  background: var(--cc-cookie-category-block-bg);
}
#s-bl .c-bl:last-child {
  margin-bottom: 0.5rem;
}
#s-bl .c-bl:first-child {
  border: none;
  margin-bottom: 2rem;
  margin-top: 0;
  padding: 0;
  transition: none;
}
#s-bl .c-bl:not(.b-ex):first-child:hover {
  background: transparent;
  background: unset;
}
#s-bl .c-bl.b-ex {
  background: #f0f4f7;
  background: var(--cc-cookie-category-block-bg);
  border: none;
  padding: 0;
  transition: none;
}
#s-bl .c-bl.b-ex + .c-bl {
  margin-top: 2rem;
}
#s-bl .c-bl.b-ex + .c-bl.b-ex {
  margin-top: 0;
}
#s-bl .c-bl.b-ex:first-child {
  margin-bottom: 1rem;
  margin-bottom: 0.5rem;
}
#s-bl .b-acc {
  display: none;
  margin-bottom: 0;
  max-height: 0;
  overflow: hidden;
  padding-top: 0;
}
#s-bl .act .b-acc {
  display: block;
  max-height: 100%;
  overflow: hidden;
}
#s-cnt .p {
  color: #2d4156;
  color: var(--cc-block-text);
  font-size: 0.9rem;
  line-height: 1.5rem;
  margin-top: 0.85rem;
}
.cc_div .b-tg .c-tgl:disabled {
  cursor: not-allowed;
}
#c-vln {
  display: table-cell;
  position: relative;
  vertical-align: middle;
}
#cs {
  bottom: 0;
  left: 0;
  padding: 0 1.7rem;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
}
#cs,
#s-inr {
  height: 100%;
}
#s-inr {
  box-shadow: 0 13px 27px -5px rgba(3, 6, 9, 0.26);
  margin: 0 auto;
  max-width: 45rem;
  opacity: 0;
  overflow: hidden;
  padding-bottom: 4.75rem;
  padding-top: 4.75rem;
  position: relative;
  transform: scale(0.96);
  visibility: hidden;
}
#s-bns,
#s-hdr,
#s-inr {
  background: #fff;
  background: var(--cc-bg);
}
#s-bl {
  display: block;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
  padding: 1.3rem 1.8rem;
  width: 100%;
}
#s-bns {
  border-top: 1px solid #f1f3f5;
  border-top: 1px solid var(--cc-section-border);
  bottom: 0;
  height: 4.75rem;
  left: 0;
  padding: 1rem 1.8rem;
  position: absolute;
  right: 0;
}
.cc_div .cc-link {
  border-bottom: 1px solid #2d4156;
  border-bottom: 1px solid var(--cc-btn-primary-bg);
  color: #2d4156;
  color: var(--cc-btn-primary-bg);
  cursor: pointer;
  display: inline;
  font-weight: 600;
  padding-bottom: 0;
  text-decoration: none;
}
.cc_div .cc-link:active,
.cc_div .cc-link:hover {
  border-color: transparent;
}
#c-bns button:first-child,
#s-bns button:first-child {
  background: #2d4156;
  background: var(--cc-btn-primary-bg);
  color: #fff;
  color: var(--cc-btn-primary-text);
}
#c-bns.swap button:first-child {
  background: #eaeff2;
  background: var(--cc-btn-secondary-bg);
  color: #2d4156;
  color: var(--cc-btn-secondary-text);
}
#c-bns.swap button:last-child {
  background: #2d4156;
  background: var(--cc-btn-primary-bg);
  color: #fff;
  color: var(--cc-btn-primary-text);
}
.cc_div .b-tg .c-tgl:checked ~ .c-tg {
  background: #2d4156;
  background: var(--cc-toggle-bg-on);
}
#c-bns button:first-child:active,
#c-bns button:first-child:hover,
#c-bns.swap button:last-child:active,
#c-bns.swap button:last-child:hover,
#s-bns button:first-child:active,
#s-bns button:first-child:hover {
  background: #1d2e38;
  background: var(--cc-btn-primary-hover-bg);
}
#c-bns.swap button:first-child:active,
#c-bns.swap button:first-child:hover {
  background: #d8e0e6;
  background: var(--cc-btn-secondary-hover-bg);
}
#s-hdr {
  border-bottom: 1px solid #f1f3f5;
  border-bottom: 1px solid var(--cc-section-border);
  display: table;
  height: 4.75rem;
  padding: 0 1.8rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
#s-hdr,
#s-ttl {
  vertical-align: middle;
}
#s-ttl {
  display: table-cell;
  font-size: 1rem;
}
#s-c-bn {
  font-size: 1.45rem;
  font-weight: 400;
  height: 1.7rem;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 1.7rem;
}
#s-c-bnc {
  display: table-cell;
  vertical-align: middle;
}
.cc_div span.t-lb {
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: -1;
}
#c_policy__text {
  height: 31.25rem;
  margin-top: 1.25rem;
  overflow-y: auto;
}
#c-s-in {
  height: 100%;
  height: calc(100% - 2.5rem);
  max-height: 37.5rem;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (min-width: 688px) {
  #s-bl::-webkit-scrollbar {
    background: transparent;
    border-radius: 0 0.25rem 0.25rem 0;
    height: 100%;
    width: 0.9rem;
  }
  #s-bl::-webkit-scrollbar-thumb {
    background: #cfd5db;
    background: var(--cc-webkit-scrollbar-bg);
    border: 0.25rem solid #fff;
    border: 0.25rem solid var(--cc-bg);
    border-radius: 100rem;
  }
  #s-bl::-webkit-scrollbar-thumb:hover {
    background: #9199a0;
    background: var(--cc-webkit-scrollbar-bg-hover);
  }
  #s-bl::-webkit-scrollbar-button {
    height: 5px;
    width: 10px;
  }
}
.cc_div .b-tg {
  bottom: 0;
  display: inline-block;
  margin: auto;
  right: 0;
  right: 1.2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
}
.cc_div .b-tg,
.cc_div .b-tg .c-tgl {
  cursor: pointer;
  position: absolute;
  top: 0;
}
.cc_div .b-tg .c-tgl {
  border: 0;
  display: block;
  left: 0;
  margin: 0;
}
.cc_div .b-tg .c-tg {
  background: #919ea6;
  background: var(--cc-toggle-bg-off);
  pointer-events: none;
  position: absolute;
  transition: background-color 0.25s ease, box-shadow 0.25s ease;
}
.cc_div .b-tg,
.cc_div .b-tg .c-tg,
.cc_div .b-tg .c-tgl,
.cc_div span.t-lb {
  border-radius: 4rem;
  height: 1.5rem;
  width: 3.4rem;
}
.cc_div .b-tg .c-tg.c-ro {
  cursor: not-allowed;
}
.cc_div .b-tg .c-tgl ~ .c-tg.c-ro {
  background: #d5dee2;
  background: var(--cc-toggle-bg-readonly);
}
.cc_div .b-tg .c-tgl ~ .c-tg.c-ro:after {
  box-shadow: none;
}
.cc_div .b-tg .c-tg:after {
  background: #fff;
  background: var(--cc-toggle-knob-bg);
  border: none;
  border-radius: 100%;
  box-shadow: 0 1px 2px rgba(24, 32, 35, 0.36);
  box-sizing: content-box;
  content: "";
  display: block;
  height: 1.25rem;
  left: 0.125rem;
  position: relative;
  top: 0.125rem;
  transition: transform 0.25s ease;
  width: 1.25rem;
}
.cc_div .b-tg .c-tgl:checked ~ .c-tg:after {
  transform: translateX(1.9rem);
}
#s-bl table,
#s-bl td,
#s-bl th {
  border: none;
}
#s-bl tbody tr {
  transition: background-color 0.25s ease;
}
#s-bl tbody tr:hover {
  background: #e9eff4;
  background: var(--cc-cookie-category-block-bg-hover);
}
#s-bl table {
  border-collapse: collapse;
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-align: left;
  width: 100%;
}
#s-bl td,
#s-bl th {
  font-size: 0.8rem;
  padding: 0.8rem 0.625rem 0.8rem 1.2rem;
  text-align: left;
  vertical-align: top;
}
#s-bl th {
  font-family: inherit;
  padding: 1.2rem;
}
#s-bl thead tr:first-child {
  border-bottom: 1px solid #e9edf2;
  border-bottom: 1px solid var(--cc-cookie-table-border);
}
.force--consent #cs,
.force--consent #s-cnt {
  width: 100vw;
}
#cm-ov,
#cs-ov {
  background: #070707;
  background: rgba(4, 6, 8, 0.85);
  background: var(--cc-overlay-bg);
  bottom: 0;
  display: none;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: none;
  visibility: hidden;
}
.c--anim #cs-ov,
.force--consent .c--anim #cm-ov,
.force--consent.show--consent #cm-ov,
.show--settings #cs-ov {
  display: block;
}
#cs-ov {
  z-index: 2;
}
.force--consent .cc_div {
  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  transition: visibility 0.25s linear;
  visibility: hidden;
  width: 100%;
  width: 100vw;
}
.force--consent.show--consent .c--anim .cc_div,
.force--consent.show--settings .c--anim .cc_div {
  visibility: visible;
}
.force--consent #cm {
  position: absolute;
}
.force--consent #cm.bar {
  max-width: 100vw;
  width: 100vw;
}
html.force--consent.show--consent {
  overflow-y: hidden !important;
}
html.force--consent.show--consent,
html.force--consent.show--consent body {
  height: auto !important;
  overflow-x: hidden !important;
}
.cc_div .act .b-bn .exp:before,
.cc_div .b-bn .exp:before {
  border: solid #2d4156;
  border: solid var(--cc-btn-secondary-text);
  border-width: 0 2px 2px 0;
  content: "";
  display: inline-block;
  left: 1.2rem;
  margin-right: 15px;
  padding: 0.2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.cc_div .act .b-bn .b-tl:before {
  transform: translateY(-20%) rotate(225deg);
}
.cc_div .on-i:before {
  border: solid #ecf2fa;
  border: solid var(--cc-toggle-knob-icon-color);
  border-width: 0 2px 2px 0;
  display: inline-block;
  left: 0.75rem;
  padding: 0.1rem 0.1rem 0.45rem;
  top: 0.37rem;
}
#s-c-bn:after,
#s-c-bn:before,
.cc_div .on-i:before {
  content: "";
  margin: 0 auto;
  position: absolute;
  transform: rotate(45deg);
}
#s-c-bn:after,
#s-c-bn:before {
  background: #2d4156;
  background: var(--cc-btn-secondary-text);
  border-radius: 1rem;
  height: 0.6rem;
  left: 0.82rem;
  top: 0.58rem;
  width: 1.5px;
}
#s-c-bn:after {
  transform: rotate(-45deg);
}
.cc_div .off-i,
.cc_div .on-i {
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  text-align: center;
  transition: opacity 0.15s ease;
  width: 50%;
}
.cc_div .on-i {
  left: 0;
  opacity: 0;
}
.cc_div .off-i:after,
.cc_div .off-i:before {
  background: #ecf2fa;
  background: var(--cc-toggle-knob-icon-color);
  content: " ";
  display: block;
  height: 0.7rem;
  margin: 0 auto;
  position: absolute;
  right: 0.8rem;
  top: 0.42rem;
  transform-origin: center;
  width: 0.09375rem;
}
.cc_div .off-i:before {
  transform: rotate(45deg);
}
.cc_div .off-i:after {
  transform: rotate(-45deg);
}
.cc_div .b-tg .c-tgl:checked ~ .c-tg .on-i {
  opacity: 1;
}
.cc_div .b-tg .c-tgl:checked ~ .c-tg .off-i {
  opacity: 0;
}
#cm.box.middle,
#cm.cloud.middle {
  bottom: auto;
  top: 50%;
  transform: translateY(-37%);
}
#cm.box.middle.zoom,
#cm.cloud.middle.zoom {
  transform: scale(0.95) translateY(-50%);
}
#cm.box.center,
#cm.cloud {
  left: 1rem;
  margin: 0 auto;
  right: 1rem;
}
#cm.cloud {
  max-width: 50rem;
  overflow: hidden;
  padding: 1.3rem 2rem;
  text-align: center;
  width: unset;
}
.cc_div .cloud #c-inr {
  display: table;
  width: 100%;
}
.cc_div .cloud #c-inr-i {
  display: table-cell;
  padding-right: 2.4rem;
  vertical-align: top;
  width: 70%;
}
.cc_div .cloud #c-txt {
  font-size: 0.85rem;
}
.cc_div .cloud #c-bns {
  display: table-cell;
  min-width: 170px;
  vertical-align: middle;
}
#cm.cloud .c-bn {
  margin: 0.625rem 0 0;
  width: 100%;
}
#cm.cloud .c-bn:first-child {
  margin: 0;
}
#cm.cloud.left {
  margin-right: 1.25rem;
}
#cm.cloud.right {
  margin-left: 1.25rem;
}
#cm.bar {
  border-radius: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  padding: 2rem;
  position: fixed;
  right: 0;
  width: 100%;
}
#cm.bar #c-inr {
  margin: 0 auto;
  max-width: 32rem;
}
#cm.bar #c-bns {
  max-width: 33.75rem;
}
#cm.bar #cs {
  padding: 0;
}
.cc_div .bar #c-s-in {
  height: 100%;
  max-height: 100%;
  top: 0;
  transform: none;
}
.cc_div .bar #s-bl,
.cc_div .bar #s-bns,
.cc_div .bar #s-hdr {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.cc_div .bar #cs {
  padding: 0;
}
.cc_div .bar #s-inr {
  border-radius: 0;
  margin: 0 0 0 auto;
  max-width: 32rem;
}
.cc_div .bar.left #s-inr {
  margin-left: 0;
  margin-right: auto;
}
.cc_div .bar #s-bl table,
.cc_div .bar #s-bl tbody,
.cc_div .bar #s-bl td,
.cc_div .bar #s-bl th,
.cc_div .bar #s-bl thead,
.cc_div .bar #s-bl tr,
.cc_div .bar #s-cnt {
  display: block;
}
.cc_div .bar #s-bl thead tr {
  left: -9999px;
  position: absolute;
  top: -9999px;
}
.cc_div .bar #s-bl tr {
  border-top: 1px solid #e9edf2;
  border-top: 1px solid var(--cc-cookie-table-border);
}
.cc_div .bar #s-bl td {
  border: none;
  padding-left: 35%;
  position: relative;
}
.cc_div .bar #s-bl td:before {
  color: #2d4156;
  color: var(--cc-text);
  content: attr(data-column);
  left: 1rem;
  overflow: hidden;
  padding-right: 0.625rem;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#cm.top {
  bottom: auto;
  top: 1.25rem;
}
#cm.left {
  left: 1.25rem;
  right: auto;
}
#cm.right {
  left: auto;
  right: 1.25rem;
}
#cm.bar.left,
#cm.bar.right {
  left: 0;
  right: 0;
}
#cm.bar.top {
  top: 0;
}
@media screen and (max-width: 688px) {
  #cm,
  #cm.cloud,
  #cm.left,
  #cm.right {
    bottom: 1rem;
    display: block;
    left: 1rem;
    margin: 0;
    max-width: 100%;
    padding: 1.4rem !important;
    right: 1rem;
    width: auto;
  }
  .force--consent #cm,
  .force--consent #cm.cloud {
    max-width: 100vw;
    width: auto;
  }
  #cm.top {
    bottom: auto;
    top: 1rem;
  }
  #cm.bottom {
    bottom: 1rem;
    top: auto;
  }
  #cm.bar.bottom {
    bottom: 0;
  }
  #cm.cloud .c-bn {
    font-size: 0.85rem;
  }
  #s-bns,
  .cc_div .bar #s-bns {
    padding: 1rem 1.3rem;
  }
  .cc_div .bar #s-inr {
    max-width: 100%;
    width: 100%;
  }
  .cc_div .cloud #c-inr-i {
    padding-right: 0;
  }
  #cs {
    border-radius: 0;
    padding: 0;
  }
  #c-s-in {
    height: 100%;
    max-height: 100%;
    top: 0;
    transform: none;
  }
  .cc_div .b-tg {
    right: 1.1rem;
    transform: scale(1.1);
  }
  #s-inr {
    border-radius: 0;
    margin: 0;
    padding-bottom: 7.9rem;
  }
  #s-bns {
    height: 7.9rem;
  }
  #s-bl,
  .cc_div .bar #s-bl {
    padding: 1.3rem;
  }
  #s-hdr,
  .cc_div .bar #s-hdr {
    padding: 0 1.3rem;
  }
  #s-bl table {
    width: 100%;
  }
  #s-inr.bns-t {
    padding-bottom: 10.5rem;
  }
  .bns-t #s-bns {
    height: 10.5rem;
  }
  .cc_div .bns-t .c-bn {
    font-size: 0.83rem;
    padding: 0.9rem 1.6rem;
  }
  #s-cnt .b-bn .b-tl {
    padding-bottom: 1.2rem;
    padding-top: 1.2rem;
  }
  #s-bl table,
  #s-bl tbody,
  #s-bl td,
  #s-bl th,
  #s-bl thead,
  #s-bl tr,
  #s-cnt {
    display: block;
  }
  #s-bl thead tr {
    left: -9999px;
    position: absolute;
    top: -9999px;
  }
  #s-bl tr {
    border-top: 1px solid #e9edf2;
    border-top: 1px solid var(--cc-cookie-table-border);
  }
  #s-bl td {
    border: none;
    padding-left: 35%;
    position: relative;
  }
  #s-bl td:before {
    color: #2d4156;
    color: var(--cc-text);
    content: attr(data-column);
    left: 1rem;
    overflow: hidden;
    padding-right: 0.625rem;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  #cm .c-bn,
  .cc_div .c-bn {
    margin-right: 0;
    width: 100%;
  }
  #s-cnt #s-rall-bn {
    margin-left: 0;
  }
  .cc_div #c-bns {
    flex-direction: column;
  }
  #c-bns button + button,
  #s-cnt button + button {
    float: unset;
    margin-left: 0;
    margin-top: 0.625rem;
  }
  #cm.box,
  #cm.cloud {
    left: 1rem;
    right: 1rem;
    width: auto;
  }
  #cm.cloud.left,
  #cm.cloud.right {
    margin: 0;
  }
  .cc_div .cloud #c-bns,
  .cc_div .cloud #c-inr,
  .cc_div .cloud #c-inr-i {
    display: block;
    min-width: unset;
    width: auto;
  }
  .cc_div .cloud #c-txt {
    font-size: 0.9rem;
  }
  .cc_div .cloud #c-bns {
    margin-top: 1.625rem;
  }
}
.cc_div.ie #c-vln {
  height: 100%;
  padding-top: 5.62rem;
}
.cc_div.ie .bar #c-vln {
  padding-top: 0;
}
.cc_div.ie #cs {
  margin-top: -5.625rem;
  max-height: 37.5rem;
  position: relative;
  top: 0;
}
.cc_div.ie .bar #cs {
  margin-top: 0;
  max-height: 100%;
}
.cc_div.ie #cm {
  border: 1px solid #dee6e9;
}
.cc_div.ie #c-s-in {
  top: 0;
}
.cc_div.ie .b-tg {
  margin-bottom: 0.7rem;
  padding-left: 1rem;
}
.cc_div.ie .b-tg .c-tgl:checked ~ .c-tg:after {
  left: 1.95rem;
}
.cc_div.ie #s-bl table {
  overflow: auto;
}
.cc_div.ie .b-tg .c-tg {
  display: none;
}
.cc_div.ie .b-tg .c-tgl {
  display: inline-block;
  height: auto;
  margin-bottom: 0.2rem;
  position: relative;
  vertical-align: middle;
}
.cc_div.ie #s-cnt .b-bn .b-tl {
  padding: 1.3rem 6.4rem 1.3rem 1.4rem;
}
.cc_div.ie .bar #s-bl td:before {
  display: none;
}
.cc_div.ie .bar #s-bl td {
  padding: 0.8rem 0.625rem 0.8rem 1.2rem;
}
.cc_div.ie .bar #s-bl thead tr {
  position: relative;
}
.cc_div.ie .b-tg .t-lb {
  filter: alpha(opacity=0);
}
.cc_div.ie #cm-ov,
.cc_div.ie #cs-ov {
  filter: alpha(opacity=80);
}

/* funky */
#cm,
#s-bl .act .b-acc,
#s-inr,
.cc_div .b-tl,
.cc_div .c-bl {
  border-radius: 1.2em;
}

.cc_div .c-bn {
  border-radius: 0.7em;
}
